











import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { definitionRouteShow } from '@/views/definitions/routes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'Definitions',

  components: {
    LayoutPage,
  },

  data() {
    return {
      definitionRouteShow,
    };
  },
});
